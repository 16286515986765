import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  isZeropsContabo: '$$ZEROPS_DATA_IS_ZEROPS_CONTABO$$',
  currentProductionEnvName: 'app_production',
  intercomAppId: 'rfywubmv',
  enableIntercom: 'true',
  documentationUrl: 'https://docs.zerops.io/documentation/',
  projectInfoUrl: 'https://zerops.io/projectinfo',
  forceSecuredWebsocket: true,
  versionInfoPath: '/assets/version.json',
  rollbarAccessToken: 'd270c426ea76465781ad6bb512eb7ef0',
  envName: 'app_production',
  appVersion: 'v4.2.0',
  githubAuthCallbackUrl: 'https://app.zerops.io/github-auth',
  gitlabAuthCallbackUrl: 'https://app.zerops.io/gitlab-auth',
  apiUrl: 'https://api.app-prg1.zerops.io/api/rest/public',
  webApiUrl: 'https://web-api.zerops.io',
  websocketHost: 'api.app-prg1.zerops.io',
  websocketLoginUrl: 'https://api.app-prg1.zerops.io/api/rest/public/web-socket/login',
  googleSiteKey: '6LdUw48UAAAAALnxsH_vUxMNlAVWrCvd-OS-g2nC',
  stripePublishableKey: 'pk_live_51OnGrVLOIvSR867sGYEpQAmyEAJnQ6qpJ6ZtfJjY3V5DoFMxErMGaUKgo7IoXMVauBkT3NQhafEk1G4bozWxWwuI004COy2aBe',
  sharedIPv4: '93.185.106.128',
  region: 'zerops',
  hideVersionWarning: 'false'
};
